import axios from "axios";
import { API_URL, API_ENDPOINT } from "./Config";
import { errorMessage } from "../components/common/errorhandling";
import { toast } from "react-toastify";

export const authAPI = {
  login: async ({ email, password }) => {
    try {
      const response = await axios.post(`${API_URL}${API_ENDPOINT.login}`, {
        email,
        password,
      });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);

    }
  },

  getUserProfile: async ({ access_token, refresh_token }) => {
    try {
      const response = await axios.get(`${API_URL}${API_ENDPOINT.profile}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      });
      errorMessage(response);
      const responseData = response.data;
      const { user } = responseData;
      responseData.user = responseData.user.email;
      responseData.user_type = user.user_type;
      responseData.country = user.country;
      responseData.last_login = user?.last_login;
      const impersonateUserIds = JSON.parse(localStorage.getItem("impersonateUserIds"));
      if (!impersonateUserIds || impersonateUserIds === null || impersonateUserIds.length == 0) {
        localStorage.setItem("impersonateUserIds", JSON.stringify([responseData?.id]));
      }

      return responseData;
    } catch (error) {
      errorMessage(error);

    }
  },

  updateProfileImage: async ({ access_token, selectedImage }) => {
    try {
      const formData = new FormData();
      formData.append("profile_image", selectedImage);
      const response = await axios.patch(
        `${API_URL}${API_ENDPOINT.profile}`,
        formData,
        {
          headers: {
            Authorization: `${access_token}`,
            Accept: "application/json",
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  updateUserProfile: async ({
    access_token,
    refresh_token,
    first_name,
    last_name,
    email,
    company_name,
    position,
    country,
  }) => {
    try {
      const response = await axios.patch(
        `${API_URL}${API_ENDPOINT.profile}`,
        {
          first_name,
          last_name,
          email,
          company_name,
          position,
          country,
        },
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      const responseData = response.data;
      const { user } = responseData;
      responseData.user = responseData.user.email;
      responseData.user_type = user.user_type;
      responseData.country = user.country;
      return responseData;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  updateUserPassword: async ({
    access_token,
    oldPassword,
    newPassword,
    confirmPassword,
  }) => {
    try {
      const response = await axios.patch(
        `${API_URL}${API_ENDPOINT.profile}`,
        {
          oldPassword,
          newPassword,
          confirmPassword,
        },
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  forgotPasswordRequest: async ({ email }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.forgot_password}`,
        { email }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  logoutRequest: async (impId) => {
    const logOutId = impId.toString()
    const logOutIdsArray = logOutId.toString().split(',');
    const preLogoutId = logOutIdsArray[0];
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.logout}?ids=${logOutId}&preId=${preLogoutId}`,
        // { ids: impId }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  resetPassword: async ({ otp, newPassword, confirmPassword }) => {
    try {
      const response = await axios.patch(
        `${API_URL}${API_ENDPOINT.reset_password}`,
        { otp, newPassword, confirmPassword }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  addUser: async ({
    access_token,
    first_name,
    last_name,
    email,
    company_name,
    country,
    zone,
    position,
  }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.add_user}`,
        {
          first_name,
          last_name,
          email,
          company_name,
          country,
          zone,
          position,
        },
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  getUsers: async ({ access_token }) => {
    try {
      const response = await axios.get(`${API_URL}${API_ENDPOINT.get_user}`, {
        headers: {
          Authorization: `${access_token}`,
          "Content-Type": "application/json",
        },
      });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);

    }
  },

  deleteUser: async ({ access_token, userId }) => {
    try {
      const response = await axios.delete(
        `${API_URL}${API_ENDPOINT.delete_user}${userId}/`,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);

    }
  },

  updateUserData: async ({
    access_token,
    userId,
    first_name,
    last_name,
    email,
    position,
    zone,
    country,
  }) => {
    try {
      const response = await axios.patch(
        `${API_URL}${API_ENDPOINT.update_user}${userId}/`,
        {
          userId,
          first_name,
          last_name,
          email,
          position,
          zone,
          country,
        },
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data;
      const { user } = responseData;
      responseData.user = responseData.user.email;
      responseData.user_type = user.user_type;
      responseData.country = user.country;
      return responseData;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  countryList: async () => {
    try {
      const response = await axios.get(`${API_URL}${API_ENDPOINT.countries}`);
      return response.data;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  getCurrencies: async ({ access_token }) => {
    try {
      const response = await axios.get(`${API_URL}${API_ENDPOINT.currencies}`, {
        headers: {
          Authorization: `${access_token}`,
          "Content-Type": "application/json",
          'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8'
        },
      });
      return response.data;
    } catch (error) {
      errorMessage(error);
      throw error;
    }
  },

  getUploadData: async ({ access_token, endPoint }) => {
    try {
      const response = await axios.get(
        `${API_URL}${endPoint}`,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      errorMessage(error);

    }
  },

  deleteUploadData: async ({ access_token, dataId }) => {
    try {
      const response = await axios.delete(
        `${API_URL}${API_ENDPOINT.delete_upload_file}${dataId}/`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      errorMessage(error);
    }
  },

  getVaultFoldersList: async ({ access_token, value }) => {
    try {
      const response = await axios.get(
        `${API_URL}${API_ENDPOINT.folder_list}?is_shared=${value}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
          method: "get",
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      errorMessage(error);
      console.log(error);
    }
  },
  moveFolderList: async ({ access_token }) => {
    try {
      const response = await axios.get(
        `${API_URL}${API_ENDPOINT.move_folder_list}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
          method: "get",
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      errorMessage(error);
      console.log(error);
    }
  },
  getSelectedFolderFileList: async (access_token, fileId, data, searchValue, page) => {
    
    try {
      let companyQuery = searchValue ? `&name=${searchValue}` : '';
      let pageNumber = page ? `&page=${page}` : '';
      let searchedFile = fileId ? `&search=${fileId}` : '';
      const response = await axios.get(
        `${API_URL}${API_ENDPOINT.get_files}?folder_id=${data}${searchedFile}${companyQuery}${pageNumber}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error:", error)
      // toast.error(error?.response?.data?.message || "Something went wrong");
      errorMessage(error);
    }
  },
  uploadSelectedFolderFile: async ({ access_token, data }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.manage_files}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
          },
          maxBodyLength: Infinity,
        }
      );
      return response?.data;
    } catch (error) {
      errorMessage(error);
    }
  },

  CreateFolderFile: async ({ access_token, data }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.createFolder}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
          },
          maxBodyLength: Infinity,
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      errorMessage(error?.response?.data?.message);
    }
  },
  deleteSelectedFolderFile: async ({ access_token, fileId }) => {
    try {
      const response = await axios.delete(
        `${API_URL}${API_ENDPOINT.deleteSelectedFile}${fileId}/`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);

    }
  },
  MoveSelectedFileToFolder: async ({ access_token, data }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.moveFileToSelectedFolder}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
          },
          maxBodyLength: Infinity,
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 400) {
        return error?.response.data;
      }
      errorMessage(error);
    }
  },
  getFileVersionHistory: async ({ access_token, data }) => {
    try {
      const response = await axios.get(
        `${API_URL}${API_ENDPOINT.getFileVersionHistory}?file_id=${data}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      console.log("error", error);
    }
  },
  deleteFileVersionHistory: async ({ access_token, data }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.deleteFileVersionHistory}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          maxBodyLength: Infinity,
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);

    }
  },
  grantFilePermissionToUser: async ({ access_token, data }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.grantFilePermission}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          maxBodyLength: Infinity,
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);

    }
  },
  getProgressUploadedFile: async ({ access_token, data }) => {
    try {
      const response = await axios.get(
        `${API_URL}${API_ENDPOINT.getProgressFiles}?folder_id=${data}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      console.log(error);
    }
  },
  renameFileName: async ({ access_token, data }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.renameFileName}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          maxBodyLength: Infinity,
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  renameFolderName: async ({ access_token, data }) => {
    const formData = new FormData();
    formData.append('name', data?.name);
    try {
      const response = await axios.put(
        `${API_URL}${API_ENDPOINT.renameFolderName}${data?.folder_id}/`,
        formData,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          maxBodyLength: Infinity,
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  deleteSelectedFolder: async ({ access_token, data }) => {
    try {
      const response = await axios.delete(
        `${API_URL}${API_ENDPOINT.deleteSelectedFolder}${data?.folder_id}/`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },


  getSelectedSharedFolderFileList: async (access_token, fileId, data, searchValue, currentPage) => {
    try {
      let companyQuery = searchValue ? `&name=${searchValue}` : '';
      let pageNumber = currentPage ? `&page=${currentPage}` : '';
      let searchedFile = fileId ? `&search=${fileId}` : '';
      const response = await axios.get(
        `${API_URL}${API_ENDPOINT.shared_files}?folder_id=${data}${searchedFile}${companyQuery}${pageNumber}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  // createWidget: async ({ access_token, data }) => {
  //   try {
  //     const response = await axios.post(
  //       `${API_URL}${API_ENDPOINT.createWidgets}/`,
  //       {
  //         headers: {
  //           Authorization: `${access_token}`,
  //         },
  //         maxBodyLength: Infinity,
  //       }
  //     );
  //     errorMessage(response);
  //     return response.data;
  //   } catch (error) {
  //     errorMessage(error);
  //     toast.error(error?.response?.data?.message || "Something went wrong");
  //     //
  //   }
  // },

  createWidget: async ({ access_token, formData }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.createWidgets}`,
        formData,
        {
          headers: {
            Authorization: `${access_token}`,
          },
          maxBodyLength: Infinity,
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },
  uploadPrintDoc: async ({ access_token, formData }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.uploadPrintDocument}`,
        formData,
        {
          headers: {
            Authorization: `${access_token}`,
          },
          maxBodyLength: Infinity,
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  getDashboardWidget: async ({ access_token }) => {
    try {
      const response = await axios.get(`${API_URL}${API_ENDPOINT.dashboardReports}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  dashboardWidgetRead: async ({ access_token, id }) => {
    try {
      const response = await axios.get(`${API_URL}${API_ENDPOINT.dashboardReportsRead}${id}/`, {
        headers: {
          Authorization: `${access_token}`,
        },
      });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  fetchImportExportWidget: async ({ access_token, data }) => {
    try {
      const response = await axios.get(`${API_URL}${API_ENDPOINT.fetchImportExportWidget}?direction=${data}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },
  fetchWidgetById: async ({ access_token, widgetId }) => {
    try {
      const response = await axios.get(
        `${API_URL}${API_ENDPOINT.fetchWidgetById}${widgetId}/`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  updateWidgetById: async ({ access_token, widgetId, formData }) => {
    try {
      const response = await axios.put(
        `${API_URL}${API_ENDPOINT.updateWidgetById}${widgetId}/`, formData,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  deleteWidgetById: async ({ access_token, widgetId }) => {
    try {
      const response = await axios.delete(
        `${API_URL}${API_ENDPOINT.deleteWidgetById}${widgetId}/`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },
  fetchInfoDisplayWidget: async ({ access_token, formData }) => {
    try {
      const response = await axios.post(`${API_URL}${API_ENDPOINT.fetchInfoDisplayWidget}`, formData, {
        headers: {
          Authorization: `${access_token}`,
        },
      });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },

  fetchCompaniesId: async ({ access_token, formData, selectedCompanyId }) => {
    try {
      const response = await axios.get(
        `${API_URL}${API_ENDPOINT.fetchCompanies}?companyId=${selectedCompanyId}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },


  getCompanyDetails: async ({ access_token, companyId, userRole, previousId, is_internal_admin }) => {
    try {
      const baseUrl = `${API_URL}${API_ENDPOINT.companyData}?${userRole}=${companyId}&preId=${previousId}`;
      const response = await axios.get(
        is_internal_admin ? `${baseUrl}&is_internal_admin=${is_internal_admin}` : baseUrl,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },
  filterFields: async ({ access_token, selectedWidgets }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.filterFields}`, { widget_name: selectedWidgets },
        {
          headers: {
            Authorization: `${access_token}`,
          },
        });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },
  findFilterData: async ({ access_token, formData }) => {
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.findFilterData}`, formData,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        });
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  },


  findSearchData: async ({ access_token, filterPayload, filter }) => {
    // try {
    //   const response = await axios.post(
    //     `${API_URL}${API_ENDPOINT.searchDocument}&filter_type=${filter}`,
    //     {
    //       headers: {
    //         Authorization: `${access_token}`,
    //       },
    //       params: filterPayload,
    //     }
    //   );
    //   errorMessage(response);
    //   return response.data;
    // } catch (error) {
    //   errorMessage(error);
    //   // toast.error(error?.response?.data?.message || "Something went wrong");
    // }
    if(filter === "filter"){
      try {
        const response = await axios.post(
          `${API_URL}${API_ENDPOINT.searchDocument}?filter_type=${filter}`,
          filterPayload,
          {
            headers: {
              Authorization: `${access_token}`,
            },
          }
        );
        errorMessage(response);
        return response.data;
      } catch (error) {
        errorMessage(error);
      }
    }else{
      try {
        
        const response = await axios.get(`${API_URL}${API_ENDPOINT.searchDocument}?filter_type=${filter}`,
          {
            headers: {
              Authorization: `${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        errorMessage(response);
        return response.data;
      } catch (error) {
        errorMessage(error);
      }
    }
    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINT.searchDocument}?filter_type=${filter}`,
        filterPayload,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      errorMessage(response);
      return response.data;
    } catch (error) {
      errorMessage(error);
    }
  },

};