import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { authAPI } from '../../services/authAPI';
import { shallowEqual, useSelector } from 'react-redux';

const SearchPopup = ({ openPopup, handleClose, fileId, onResponseGet }) => {
  const [filters, setFilters] = useState({
    when: null,
    who: '',
    where: '',
    howBig: '',
    how: '',
  });
  const [loading, setLoading] = useState(false);
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handleClearAll = () => {
    setFilters({
      when: null,
      who: '',
      where: '',
      howBig: '',
      how: '',
    });
  };

  const handleFilterSubmit = async () => {
    const filterPayload = {
      filters: [
        // Only include filters that have values
        filters?.when?.isValid() && {
          type: "when",
          value: filters.when.format('YYYY-MM-DD'),
        },
        filters?.who && {
          type: "who",
          value: filters.who,
        },
        filters?.where && {
          type: "where",
          value: filters.where,
        },
        filters?.howBig && {
          type: "howBig",
          value: filters.howBig,
        },
        filters?.how && {
          type: "how",
          value: filters.how,
        },
      ].filter(Boolean),
    };
    const filter = 'filter'
    setLoading(true);
    try {
      const response = await authAPI.findSearchData({ access_token, filterPayload, filter });
      // Call the callback function if provided
      if (onResponseGet) {
        onResponseGet(response);
      }
    } catch (error) {
      console.error("Error fetching search data:", error);
    }
    setLoading(false);
  };

  return (
    <>
    
    <Dialog open={openPopup} onClose={handleClose} maxWidth="xs" fullWidth className='filter-popup'>
      <DialogTitle>
        Filters
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {loading && (
        <div className="loader-overlay">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: '#ff6b04' }} />
          </Box>
        </div>
      )}
      <DialogContent dividers>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="when"
            value={filters.when}
            onChange={(newValue) => handleFilterChange('when', newValue)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
        </LocalizationProvider>

        <TextField
          label="who"
          value={filters.who}
          onChange={(e) => handleFilterChange('who', e.target.value)}
          fullWidth
          margin="normal"
          placeholder="Enter Email/Name...."
        />
        <TextField
          label="where"
          value={filters.where}
          onChange={(e) => handleFilterChange('where', e.target.value)}
          fullWidth
          margin="normal"
          placeholder="Folder name (e.g., clientAdminVault or groupAdminVault)"
        />

        {/* <FormControl fullWidth margin="normal">
          <InputLabel>Select Directory</InputLabel>
          <Select
            value={filters.where}
            onChange={(e) => handleFilterChange('where', e.target.value)}
            label="where"
          >
            <MenuItem value="Directory 1">Directory 1</MenuItem>
            <MenuItem value="Directory 2">Directory 2</MenuItem>
          </Select>
        </FormControl> */}

        <FormControl fullWidth margin="normal">
          <InputLabel>Select Range</InputLabel>
          <Select
            value={filters.howBig}
            onChange={(e) => handleFilterChange('howBig', e.target.value)}
            label="how_big"
          >
            <MenuItem value="0-5">0MB - 5MB</MenuItem>
            <MenuItem value="5-10">5MB -10MB</MenuItem>
            <MenuItem value="10-15">10MB - 15MB</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Files Extension</InputLabel>
          <Select
            value={filters.how}
            onChange={(e) => handleFilterChange('how', e.target.value)}
            label="how"
          > <MenuItem value=".csv">CSV</MenuItem>
            <MenuItem value=".doc">DOC</MenuItem>
            <MenuItem value=".docx">DOCX</MenuItem>          
            <MenuItem value=".jpeg">JPEG</MenuItem>     
            <MenuItem value=".jpg">JPG</MenuItem>    
            <MenuItem value=".pdf">PDF</MenuItem>
            <MenuItem value=".png">PNG</MenuItem>
            <MenuItem value=".xlsx">XLSX</MenuItem>
            <MenuItem value=".xls">XLS</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClearAll} variant="outlined" className='button bg-orange'>
          Clear All
        </Button>
        <Button onClick={handleFilterSubmit} variant="contained" className='button-default'>
          Filter
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default SearchPopup;
