import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, Collapse, Grid, List, ListItem,ListItemText, TextField, Paper, Typography, Popper, ClickAwayListener, IconButton, CircularProgress } from "@mui/material";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { NavLink, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import Userpic from "../../assets/images/user-avatar.svg";
import { authAPI } from "../../services/authAPI";
import { setAccessToken, signOutManager} from "../../reducers/authSlice";
import { useGlobalContext } from "../../hook/globalstate";
import Swal from 'sweetalert2';

const Header = ({ onToggleSidebar }) => {
  const dispatch = useDispatch();
  const { state, setState } = useGlobalContext();
  const [todayDate, setTodayDate] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCompany, setOpenCompany] = useState({});
  const [openAdmin, setOpenAdmin] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchUserTerm, setSearchUserTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorUserEl, setAnchorUserEl] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const userProfile = useSelector((state) => state.userProfile);
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  const checkGroupAdmin = userProfile?.user_type === "is_group_admin";
  const checkClientAdmin = userProfile?.user_type === "is_client_admin";
  const checkClientUser = userProfile?.user_type === "is_client_user";
  const checkInternalAdmin = userProfile?.is_internal_admin;
  const companyId = userProfile?.default_client || 0;
  const navigate = useNavigate()
  const checkImpUser = JSON.parse(localStorage.getItem("impersonateUserIds"));
  const previousId = checkImpUser && checkImpUser.length > 0 ? checkImpUser[checkImpUser.length - 1] : null; // Ensure there are at least two IDs
 
  
  // useEffect(() => {
  //   if(checkInternalAdmin === true){
  //     navigate("/");
  //   }
  // }, [checkInternalAdmin, navigate]);

  useEffect(() => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    setTodayDate(formattedDate);
  }, []);

  const handleExpandToggle = (type, id) => {
    if (type === "company") {
      setOpenCompany((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    } else if (type === "admin") {
      setOpenAdmin((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    }
  };

  // Fetch companies
  const fetchCompanies = async () => {
    // setLoading(true);
    try {
      const selectedCompanyId = checkGroupAdmin ? companyId : userProfile?.id || 0;
      const formData = new FormData();
      formData.append("companyId", selectedCompanyId);

      const response = await authAPI.fetchCompaniesId({ access_token, formData, selectedCompanyId });
      let processedData;
      if (checkGroupAdmin ||  (checkClientUser && checkInternalAdmin) ) {
        //const companies = response?.result?.companies || response?.result?.client_users;
        processedData = response?.result?.companies?.map((company) => {
          return ({
            id: company?.id,
            company_name: company?.company_name ? company?.company_name : company?.first_name,
            mySelf: company?.Myself || [],
            clientAdmins: company?.clientAdmin || [],
            first_name: company?.first_name,
            last_name: company?.last_name,
            full_name: company?.full_name,
          })
        });
      }  else if (checkClientAdmin && checkInternalAdmin) {
        processedData = response?.result?.companies?.map((company) => {
          return ({
            id: company?.id,
            company_name: company?.company_name ? company?.company_name : company?.first_name,
            mySelf: company?.Myself || [],
            clientAdmins: company?.clientAdmin || [],
            first_name: company?.first_name,
            last_name: company?.last_name,
            full_name: company?.full_name,
          })
        });

        const processedUserData = response?.result?.client_users?.map((users) => {
          return ({
            id: users?.id,
            first_name: users?.first_name,
            last_name: users?.last_name,
            full_name: users?.full_name,
          })
        });
        setUserData(processedUserData)
      } else if (checkClientAdmin ) {
        processedData = response?.result?.client_users?.map((users) => {
          return ({
            id: users?.id,
            first_name: users?.first_name,
            last_name: users?.last_name,
            full_name: users?.full_name,
          })
        });
      } else {
        processedData = [];
      }
       
      
      setCompanyData(processedData);
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setLoading(false);
    }
  };

  const debouncedFetchCompanies = useCallback(debounce(() => {
    if (access_token && (checkGroupAdmin || checkClientAdmin || checkInternalAdmin)) {
      fetchCompanies();      
    }
  }, 1200), [access_token, checkGroupAdmin, checkClientAdmin, companyId, checkInternalAdmin]);

  useEffect(() => {
    debouncedFetchCompanies();
    return () => debouncedFetchCompanies.cancel();
  }, [debouncedFetchCompanies]);
  const handleAdminClick = async (adminId, adminName) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to impersonate ${adminName}.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, impersonate!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
      if (document.body.classList.contains('impersonateDropDown')) {
        document.body.classList.remove("impersonateDropDown");
      } else {
        document.body.classList.add("impersonateDropDown");
      }

      setLoading(true);
      setOpenAdmin((prevState) => ({
        ...prevState,
        [adminId]: !prevState[adminId],
      }));

      if (adminId) {
        try {
          const response = await authAPI.getCompanyDetails({ access_token, companyId: adminId, userRole: 'clientAdminId', previousId });
          console.log("🚀 ~ handleAdminClick ~ response:", response)
          localStorage.setItem("impersonateUserToken", response?.token[0]?.access);
          localStorage.setItem("impersonateUserRefreshToken", response?.token[0]?.refresh);
          const response_values = JSON.stringify({ access: response?.token[0]?.access, refresh: response?.token[0]?.refresh, payload_data: response.storage });
          dispatch(setAccessToken(JSON.parse(response_values)));
          setState({ type: "fetchWidgetReport", payload: adminId });
          setLoading(false);
        } catch (error) {
          toast.error(error.message);
          setLoading(false);
        }
      }

      const impersonateUserIds = localStorage.getItem("impersonateUserIds");
      let arr = JSON.parse(impersonateUserIds);
      if (adminId && !arr?.includes(adminId)) {
        arr?.push(adminId);
      }
      // Store the updated array back to localStorage
      localStorage.setItem("impersonateUserIds", JSON.stringify(arr));
      handleAdminSelected(adminId); // Select admin when clicked
    }
  };

  // Function to handle admin selection
  const handleAdminSelected = (adminId) => {
    const selectedCompany = companyData?.find(company =>
      company.clientAdmins?.some(admin => admin?.id === adminId)
    );
    const selectedAdmin = selectedCompany?.clientAdmins?.find(admin => admin?.id === adminId);
    setSelectedAdmin(selectedAdmin);
    setSelectedUser(null);
  };

  // Handle client user click and show in box
  const handleClientUserClick = async (userId, userName, adminId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to impersonate ${userName}.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, impersonate!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
      if (document.body.classList.contains('impersonateDropDown')) {
        document.body.classList.remove("impersonateDropDown");
      } else {
        document.body.classList.add("impersonateDropDown");
      }
      
      let role = 'is_internal_admin';
      role = checkInternalAdmin ? 'internalAdminId' : 'client_user_id';
      
      setLoading(true);
      let selectedUserId = userId;
      if (selectedUserId) {
        try {
          const response = await authAPI.getCompanyDetails({ access_token, companyId: selectedUserId, userRole: role, previousId, is_internal_admin: checkInternalAdmin });
          
          localStorage.setItem("impersonateUserToken", response?.token[0]?.access);
          localStorage.setItem("impersonateUserRefreshToken", response?.token[0]?.refresh);
          const response_values = JSON.stringify({ access: response?.token[0]?.access, refresh: response?.token[0]?.refresh, payload_data: response.storage });
          
          dispatch(setAccessToken(JSON.parse(response_values)));
          setState({ type: "fetchWidgetReport", payload: selectedUserId });
          setLoading(false);
        } catch (error) {
          toast.error(error.message);
          setLoading(false);
        }
      }

      const impersonateUserIds = localStorage.getItem("impersonateUserIds");
      let arr = JSON.parse(impersonateUserIds);
      if (selectedUserId && !arr?.includes(selectedUserId)) {
        arr?.push(selectedUserId);
      }
      localStorage.setItem("impersonateUserIds", JSON.stringify(arr));
      setSearchTerm(selectedUser?.first_name)
      setSelectedUser(selectedUser);
    }
  };


    // Handle client user click and show in box
    const handleInternalUserClick = async (userId, userName, adminId) => {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to impersonate ${userName}.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, impersonate!',
        cancelButtonText: 'No, cancel!',
      });
  
      if (result.isConfirmed) {
        if (document.body.classList.contains('impersonateDropDown')) {
          document.body.classList.remove("impersonateDropDown");
        } else {
          document.body.classList.add("impersonateDropDown");
        }
        
        let role = 'is_internal_admin';
        role = 'client_user_id';
        
        setLoading(true);
        let selectedUserId = userId;
        if (selectedUserId) {
          try {
            const response = await authAPI.getCompanyDetails({ access_token, companyId: selectedUserId, userRole: role, previousId, is_internal_admin: checkInternalAdmin });
            
            localStorage.setItem("impersonateUserToken", response?.token[0]?.access);
            localStorage.setItem("impersonateUserRefreshToken", response?.token[0]?.refresh);
            const response_values = JSON.stringify({ access: response?.token[0]?.access, refresh: response?.token[0]?.refresh, payload_data: response.storage });
            
            dispatch(setAccessToken(JSON.parse(response_values)));
            setState({ type: "fetchWidgetReport", payload: selectedUserId });
            setLoading(false);
          } catch (error) {
            toast.error(error.message);
            setLoading(false);
          }
        }
  
        const impersonateUserIds = localStorage.getItem("impersonateUserIds");
        let arr = JSON.parse(impersonateUserIds);
        if (selectedUserId && !arr?.includes(selectedUserId)) {
          arr?.push(selectedUserId);
        }
        localStorage.setItem("impersonateUserIds", JSON.stringify(arr));
        setSearchTerm(selectedUser?.first_name)
        setSelectedUser(selectedUser);
      }
    };


  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Update filteredData based on the new search term
    const newFilteredData = filterData(companyData, value);
    setFilteredData(newFilteredData);
    setAnchorEl(event.currentTarget)
  };

  const handleUserSearchChange = (event) => {
    const value = event.target.value;
    setSearchUserTerm(value);

    // Update filteredData based on the new search term
    const newFilteredData = filterData(userData, value);
    setFilteredUserData(newFilteredData);
    setAnchorUserEl(event.currentTarget)
  };

  // Filter function based on search term
  const filterData = (data, value) => {
    if (value == '' || value== undefined || value== null) return data;
    return data?.filter((company) => {
      const companyMatch = company?.full_name?.toLowerCase()?.includes(value?.toLowerCase());

      const adminMatch = company?.clientAdmins?.some((admin) =>
        `${admin.first_name} ${admin.last_name}`?.toLowerCase()?.includes(value?.toLowerCase())
      );
      const adminFullNameMatch = company?.clientAdmins?.some((admin) =>
        `${admin.full_name}`.toLowerCase()?.includes(value?.toLowerCase())
      );

      const userMatch = company?.clientAdmins?.some((admin) =>
        admin.clientUser?.some((user) =>
          `${user?.first_name} ${user?.last_name}`?.toLowerCase()?.includes(value?.toLowerCase())
        )
      );
      const userFullNameMatch = company?.clientAdmins?.some((admin) =>
        admin.clientUser?.some((user) =>
          `${user?.full_name}`?.toLowerCase()?.includes(value?.toLowerCase())
        )
      );

      return companyMatch || adminMatch || userMatch || adminFullNameMatch || userFullNameMatch;
    });
  };

  // Get filtered company data initially
  useEffect(() => {
    setFilteredData(filterData(companyData,searchTerm));
  }, [companyData]);

  // Get filtered company data initially
  useEffect(() => {
    setFilteredUserData(filterData(userData,searchTerm));
  }, [userData]);

  const handleInputClick = (event) => {
    document.body.classList.remove("impersonateDropDown");
    setAnchorEl(anchorEl ? null : event?.currentTarget);
  };

  const handleUserInputClick = (event) => {
    document.body.classList.remove("impersonateDropDown");
    setAnchorUserEl(anchorUserEl ? null : event?.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleUserClickAway = () => {
    setAnchorUserEl(null);
  };

  // Handle logout
  const handleLogout = async () => {

    let userId = userProfile?.id;
    setLoading(true);
    let impersonateUserIds = localStorage.getItem("impersonateUserIds");
    // Parse the existing array from localStorage
    let arr = JSON.parse(impersonateUserIds);
    // Check if selectedUserId is already in the array to avoid duplicates
    if (userId && arr?.includes(userId)) {
      arr?.pop(userId);
    }
    const newUSerId = arr[(arr?.length - 1)];
    let impAccessToken = "";
    let impRefreshToken = "";
    try {
      const response = await authAPI.logoutRequest(arr);
      impAccessToken = response?.token[0]?.access;
      impRefreshToken = response?.token[0]?.refresh;
      localStorage.setItem("impersonateUserToken", impAccessToken);
      localStorage.setItem("impersonateUserRefreshToken", impRefreshToken);
      setState({ type: "fetchWidgetReport", payload: newUSerId });
    } catch (error) {
      console.error('Failed to reset password. Please try again.');
    }
    setLoading(false);
    dispatch(signOutManager());
    const response_values = JSON.stringify({ access: impAccessToken, refresh: impRefreshToken });
    dispatch(setAccessToken(JSON.parse(response_values)));

  };
  return (
    <header className="flex justify-between items-center">
      <div className="header-right">
        <button className="sidebar-toggle" onClick={onToggleSidebar}>
          <svg width="19" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 3.9375C0 3.21152 0.606473 2.625 1.35714 2.625H17.6429C18.3935 2.625 19 3.21152 19 3.9375C19 4.66348 18.3935 5.25 17.6429 5.25H1.35714C0.606473 5.25 0 4.66348 0 3.9375ZM0 10.5C0 9.77402 0.606473 9.1875 1.35714 9.1875H17.6429C18.3935 9.1875 19 9.77402 19 10.5C19 11.226 18.3935 11.8125 17.6429 11.8125H1.35714C0.606473 11.8125 0 11.226 0 10.5ZM19 17.0625C19 17.7885 18.3935 18.375 17.6429 18.375H1.35714C0.606473 18.375 0 17.7885 0 17.0625C0 16.3365 0.606473 15.75 1.35714 15.75H17.6429C18.3935 15.75 19 16.3365 19 17.0625Z"
              fill="black"
            />
          </svg>
        </button>
        <div className="current-status">
          <div className="current-date">
            <span>Today’s Date</span>
            <h5>{todayDate}</h5>
          </div>
          <div className="total-report">
            <span>Total report</span>
            <h5>{userProfile?.report_count}</h5>
          </div>
        </div>
      </div>
      <Grid container justifyContent="flex-end" alignItems="center" >
        {checkImpUser?.length > 1 &&
          <Typography className="groupAdmin-text" sx={{ pr: 2, fontSize: '12px' }}>
            You are now working as: <strong>{userProfile?.full_name}</strong>, User Type: <strong>{userProfile?.type}</strong>
          </Typography>
        }

        {loading ? (
          <div className="loader-overlay">
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress sx={{ color: '#ff6b04' }} />
            </Box>
          </div>
        ) : (
          <>
            {(checkClientAdmin && checkInternalAdmin) && (
              <Box sx={{ width: "250px", marginRight: "15px" }} className="headerfilter">
                {/* Search box */}
                <TextField
                  label="Search User"
                  value={searchUserTerm}
                  onClick={handleUserInputClick}
                  onChange={handleUserSearchChange}
                  fullWidth
                  disabled={loading}
                />
                {/* Dropdown that opens on click */}
                
                <Popper open={Boolean(anchorUserEl)} anchorEl={anchorUserEl} placement="bottom-start" className="search-dropdown sdsdsdsdd">
                  <ClickAwayListener onClickAway={handleUserClickAway}>
                    <Paper sx={{ maxHeight: 400, overflow: "auto", width: 250 }} className="dropdown-inner scrollablesidebar">
                      <List>
                        {filteredUserData?.length > 0 ? (
                          filteredUserData?.map((user) => (
                            <React.Fragment key={user.id}>
                              <ListItem
                              sx={{ pl: 6, cursor: "pointer" }}
                              onClick={() => handleInternalUserClick(user?.id, user?.full_name, userProfile?.id)}
                              >
                                <ListItemText primary={user?.full_name} />
                              </ListItem>
                            </React.Fragment>
                          ))
                        ) : (
                          <ListItem>
                            <ListItemText primary="No matching users found" />
                          </ListItem>
                        )}
                      </List>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </Box>
            )}
            
            {(checkGroupAdmin || checkClientAdmin || checkInternalAdmin || (checkClientUser && checkInternalAdmin)) && (
              <Box sx={{ width: "250px", marginRight: "15px" }} className="headerfilter">
                {/* Search box */}
                <TextField
                  label= {checkGroupAdmin ? "Search client admin, client user" : (checkClientUser || checkClientAdmin ) && checkInternalAdmin ? 'Search group admin' : checkClientAdmin ? 'Search client user' :  'Search company' }
                  value={searchTerm}
                  onClick={handleInputClick}
                  onChange={handleSearchChange}
                  fullWidth
                  disabled={loading}
                />
                {/* Dropdown that opens on click */}
                {checkGroupAdmin ? (
                  <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" className="search-dropdown">
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Paper sx={{ maxHeight: 400, overflow: "auto", width: 250 }} className="dropdown-inner scrollablesidebar">
                        <List>
                          {filteredData?.length > 0 ? (
                            filteredData?.map((company) => (
                              <React.Fragment key={company.id}>
                                <ListItem>
                                  <ListItemText primary={company?.company_name} />
                                  <IconButton
                                    size="small"
                                    onClick={() => handleExpandToggle("company", company?.id)}
                                  >
                                    {openCompany[company?.id] ? <ExpandLess /> : <ExpandMore />}
                                  </IconButton>
                                </ListItem>

                                <Collapse in={openCompany[company?.id]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {company?.mySelf?.map((admin) => (
                                      <React.Fragment key={admin?.id}>
                                        <ListItem>
                                          <ListItemText sx={{ cursor: "pointer" }} primary={`My Self`} />
                                          {admin.clientUser.length > 0 && (
                                            <IconButton
                                              size="small"
                                              onClick={() => handleExpandToggle("admin", admin?.id)}
                                            >
                                              {openAdmin[admin?.id] ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                          )}
                                        </ListItem>

                                        <Collapse in={openAdmin[admin?.id]} timeout="auto" unmountOnExit>
                                          <List component="div" disablePadding>
                                            {admin?.clientUser?.map((user) => {
                                              return (
                                                <ListItem
                                                  key={user.id}
                                                  sx={{ pl: 6, cursor: "pointer" }}
                                                  onClick={() => handleClientUserClick(user?.id, user?.full_name, admin?.id)}
                                                >
                                                  <ListItemText primary={`${user?.first_name} ${user?.last_name}`} />
                                                </ListItem>
                                              )
                                            })}
                                          </List>
                                        </Collapse>
                                      </React.Fragment>
                                    ))}
                                  </List>
                                  <List component="div" disablePadding>
                                    {company?.clientAdmins?.map((admin) => (
                                      <React.Fragment key={admin?.id}>
                                        <ListItem>
                                          <ListItemText sx={{ cursor: "pointer" }} primary={`${admin.first_name} ${admin.last_name}`} onClick={() => handleAdminClick(admin?.id, admin?.full_name)} />
                                          {admin.clientUser.length > 0 && (
                                            <IconButton
                                              size="small"
                                              onClick={() => handleExpandToggle("admin", admin?.id)}
                                            >
                                              {openAdmin[admin?.id] ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                          )}
                                        </ListItem>

                                        <Collapse in={openAdmin[admin?.id]} timeout="auto" unmountOnExit>
                                          <List component="div" disablePadding>
                                            {admin?.clientUser?.map((user) => {
                                              return (
                                                <ListItem
                                                  key={user.id}
                                                  sx={{ pl: 6, cursor: "pointer" }}
                                                  onClick={() => handleClientUserClick(user?.id, user?.full_name, admin?.id)}
                                                >
                                                  <ListItemText primary={`${user?.first_name} ${user?.last_name}`} />
                                                </ListItem>
                                              )
                                            })}
                                          </List>
                                        </Collapse>
                                      </React.Fragment>
                                    ))}
                                  </List>
                                </Collapse>
                              </React.Fragment>
                            ))
                          ) : (
                            <ListItem>
                              <ListItemText primary="No matching companies found" />
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                ) : checkClientAdmin ? (
                  <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} className="search-dropdown">
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Paper sx={{ maxHeight: 400, overflow: "auto", width: 250 }} className="dropdown-inner scrollablesidebar">
                        <List component="div" disablePadding>
                          {filteredData?.length > 0 ? (
                            filteredData?.map((user) => {
                              return (
                                <ListItem
                                  key={user?.id}
                                  sx={{ pl: 2, cursor: "pointer", borderBottom: "solid 1px #f2f2f2" }}
                                  onClick={() => handleClientUserClick(user?.id, user?.full_name)}
                                >
                                  <ListItemText
                                    primary={
                                      user?.full_name
                                        ? `${user?.full_name}`
                                        : "No Name Provided"
                                    }
                                  />
                                </ListItem>
                              )
                            })
                          ) : null}
                        </List>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                ) : checkInternalAdmin ? (
                  <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} className="search-dropdown">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Paper sx={{ maxHeight: 400, overflow: "auto", width: 250 }} className="dropdown-inner scrollablesidebar">
                      <List component="div" disablePadding>
                        {filteredData?.length > 0 ? (
                          filteredData?.map((user) => {
                            return (
                              <ListItem
                                key={user?.id}
                                sx={{ pl: 2, cursor: "pointer", borderBottom: "solid 1px #f2f2f2" }}
                                onClick={() => handleClientUserClick(user?.id, user?.full_name)}
                              >
                                <ListItemText
                                  primary={
                                    user?.full_name
                                      ? `${user?.full_name}`
                                      : "No Name Provided"
                                  }
                                />
                              </ListItem>
                            )
                          })
                        ) : null}
                      </List>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
                ): null}
              </Box>
            )}
          </>
        )}
        <Menu
          menuButton={
            <MenuButton id="userpic">
              <img src={userProfile?.profile_image ? userProfile?.profile_image : Userpic} alt="" />{" "}
              {userProfile?.first_name} {userProfile?.last_name}
            </MenuButton>
          }
        >
          {checkImpUser?.length == 1 &&
            <>
              <MenuItem>
                <NavLink to="/update-profile" activeClassName="active">
                  <span>Profile</span>
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/update-password" activeClassName="active">
                  <span>Change Password</span>
                </NavLink>
              </MenuItem>
              {!checkClientUser && (
                <MenuItem>
                  <NavLink to="/view-user" activeClassName="active">
                    <span>Users</span>
                  </NavLink>
                </MenuItem>
              )}
            </>
          }
          <MenuItem onClick={handleLogout}>
            <NavLink activeClassName="active">
              <span>{checkImpUser?.length > 1 ? `Logout from ${userProfile?.type}` : "Logout"}</span>
            </NavLink>
          </MenuItem>
        </Menu>
      </Grid>
    </header>
  );
};

export default React.memo(Header);