export const API_URL = process.env.REACT_APP_LOCAL_PRODUCTION_URL;
export const ANALYTICAL_URL = process.env.REACT_APP_ANALYTICAL_URL;
// process.env.NODE_ENV === "development"
//   ? process.env.REACT_APP_DEVELOPMENT_URL
//   : process.env.REACT_APP_LOCAL_PRODUCTION_URL;

export const API_ENDPOINT = {
  login: "/api/account/user/login/",
  logout: "/api/account/logout/",
  profile: "/api/account/user/profile/",
  forgot_password: "/api/account/user/forgot-password/",
  reset_password: "/api/account/user/reset-password/",
  add_user: "/api/account/add-user/",
  get_user: "/api/account/get-users/",
  delete_user: "/api/account/delete-user/",
  update_user: "/api/account/update-user/",
  countries: "/api/account/countries/",
  upload_files: "/api/analytics/uploaded-files/",
  client_user_upload_files: "/api/analytics/user-uploads/",
  // upload_files: "/api/analytics/upload/",
  delete_upload_file: "/api/analytics/delete-data/",
  folder_list: "/api/vault/folder-list/",
  move_folder_list: "/api/vault/move-folder-list/",
  get_files: "/api/vault/manage-files/",
  manage_files: "/api/vault/manage-files/",
  deleteSelectedFile: "/api/vault/manage-files/",
  moveFileToSelectedFolder: "/api/vault/move-files/",
  getFileVersionHistory: "/api/vault/file-versions/",
  deleteFileVersionHistory: "/api/vault/delete-file-versions/",
  grantFilePermission: "/api/vault/grant-client-permission/",
  getProgressFiles: "/api/vault/get-progress-files/",
  renameFileName: "/api/vault/file-rename/",
  renameFolderName: "/api/vault/folder-list/",
  deleteSelectedFolder: "/api/vault/folder-list/",
  currencies:"/api/analytics/widgets/currencies/",
  createFolder:"/api/vault/folder-list/",
  companyData:"/api/account/group-admin/companies/",
  shared_files: "/api/vault/shared-files/",
  createWidgets: "/api/dashboards/report/",
  fetchWidgetById: "/api/dashboards/report/",
  updateWidgetById: "/api/dashboards/report/",
  deleteWidgetById: "/api/dashboards/report/",
  dashboardReports: "/api/dashboards/report/",
  dashboardReportsRead: "/api/dashboards/report/",
  fetchImportExportWidget: "/api/widgets/",
  fetchInfoDisplayWidget: "/api/data/",
  fetchCompanies: "/api/account/group-admin/companies",
  filterFields:"/api/analytics/filter-fields/",
  findFilterData:"/api/data/",
  uploadPrintDocument:"/api/vault/document-upload/",
  searchDocument:"/api/vault/document-search/",
};