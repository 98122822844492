import "@cyntler/react-doc-viewer/dist/index.css";
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from '@mui/material';
import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

const FileViewPopup = ( {openPopup, handleClose, result} ) => {
    const extension = result?.name?.slice(((result?.name?.lastIndexOf(".") - 1) >>> 0) + 2);
   
    let fileType = "pdf";
    let docs = [
        { uri: result?.url }    
      ];
    if(extension == "png" 
        || extension == "jpg" 
        || extension == "jpeg" 
        || extension == "JPEG" 
        || extension == "JPG" 
        || extension == "PNG"){
            fileType = 'image';
        }else if(extension == "pdf" 
            || extension == "PDF"){
                fileType = "pdf";                
            }else{
                fileType = "csv";    
            }
    return (
        <>
        <Dialog open={openPopup} onClose={handleClose} maxWidth="xl" fullWidth>
            <DialogTitle>
                {result?.name}
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {fileType === "pdf" ? (
                    <iframe
                        src={`${result?.url}#toolbar=0&navpanes=0`}
                        type="application/pdf"
                        title={result?.name}
                        style={{
                            width: '100%',
                            minHeight: '600px',
                        }}
                    />
                ) : fileType === "image" ? (
                    <img
                        src={`${result?.url}#toolbar=0&navpanes=0`}                        
                        title={result?.name}
                        style={{
                            margin: '0 auto'
                        }}
                    />
                ) : (
                    <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
                )}
            </DialogContent>
        </Dialog>
        </>
    )
}

export default FileViewPopup