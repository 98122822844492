import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import IconCurrency from '../../../assets/images/icon-currency-coins.svg';
import IconDelete from '../../../assets/images/icon-delete.svg';
import IconEdit from '../../../assets/images/icon-edit.svg';
import IconFilter from '../../../assets/images/icon-filter.svg';
import IconPlus from '../../../assets/images/icon-plus.svg';
import IconPrinter from '../../../assets/images/icon-printer.svg';
import IconSave from '../../../assets/images/icon-save.svg';
import { useGlobalContext } from '../../../hook/globalstate';

// Reusable IconButton component with Tooltip
const ActionButton = ({ title, onClick, icon, sx }) => (
  <Tooltip title={title}>
    <IconButton onClick={onClick}>
      {React.cloneElement(icon, { sx })}
    </IconButton>
  </Tooltip>
);


const Index = ({ handleActionClick, widgetId }) => {
  const currentLocation = useLocation();
  const userProfile = useSelector((state) => state.userProfile);
  const navigate = useNavigate();
  const parts = currentLocation?.pathname.split('/');
  const widgetIds = parts[parts.length - 1];
  const checkAdmin = userProfile?.user_type === 'is_group_admin';
  const checkClientUSer = userProfile?.user_type === 'is_client_user';
  const { states, setState } = useGlobalContext()


  const handleClose = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
      allowOutsideClick: false,
      showCloseButton: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Would you like?",
        text: `To save this data before close`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, save it!",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          handleActionClick(currentLocation?.pathname === '/create-report' ? 'Save' : "UpdateWidget");
        } else if (result?.dismiss === Swal.DismissReason.cancel) {
          navigate(`/analyticals`);
        }
      });
  }

  // Handles the action click and passes the action name
  const handleClick = (action) => {
    
    if(action === 'Close'){
      handleClose()
      return false
    }
    handleActionClick(action);
    if(action === 'Print'){
      window.dispatchEvent(new Event('printInitilize'));
    }
    if(action === 'Add' || action === 'Edit'){
      setState({ type: "clearSettingData" });
    }
    if(action === 'Add'){
      localStorage.removeItem('selectedReportId');
    }
  };

  // Styles object
  const styles = {
    card: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '0',
      width: '100%',
      height: '100px', 
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '8px 10px 8px 0',
      backgroundColor:'#194260',
      Padding:'0'
    },
    title: {
      color: '#fff',
      fontWeight: '400',
      fontSize: '16px'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: 'auto',
      Padding:'0px',
    },
    icons: {
      default: { color: '#fff', backgroundColor: '#1a425f', borderRadius: '5px',Padding:'0'},
      add: { color: '#fff', backgroundColor: '#1a425f', borderRadius: '5px',Padding:'0' },
      delete: { color: '#BE0000', backgroundColor: '#EDF7FF', borderRadius: '5px',Padding:'0' },
      save: { color: '#2b8915', backgroundColor: '#EDF7FF', borderRadius: '5px',Padding:'0' },

    }
  };

  return (
    <Card sx={styles.card} className='createCard kpibox'>
      <CardContent className='actionbox'>
        <Typography sx={styles.title} mb={1} >
          Actions
        </Typography>
        <Box sx={styles.buttonContainer}>
          {currentLocation?.pathname === '/analyticals' && (
            <> 
             {/* {!checkAdmin ? ( */}
                <ActionButton title="Add your report" onClick={() => handleClick('Add')} icon={<img src={IconPlus} alt="Add Icon" style={{ width: '18px', height: '18px' }} />} />
              {/* ) : null} */}
              {widgetId &&
                <>
                {/* {!checkAdmin ? ( */}
                  <ActionButton title="Edit your report" onClick={() => handleClick('Edit')} icon={<img src={IconEdit} alt="Edit Icon" style={{ width: '18px', height: '18px'}} />} sx={styles.icons.default} />
                {/* ) : null} 
                {!checkClientUSer ? (*/}
                  <ActionButton title="Print your report" onClick={() => handleClick('Print')} icon={<img src={IconPrinter} alt="Print Icon" style={{ width: '18px', height: '18px' }} />} sx={styles.icons.default} />
                 {/* ) : null} */}
                </>
              }
            </>
          )}

          {/* <ActionButton title="By Date" onClick={() => handleClick('Event')} icon={<img src={IconCalendar} alt="Event Icon" style={{ width: '18px', height: '18px' }} />} sx={styles.icons.default} /> */}
          <ActionButton title="Filter" onClick={() => handleClick('Filter')} icon={<img src={IconFilter} alt="Add Filter" style={{ width: '18px', height: '18px' }} />} sx={styles.icons.default} />
          {/* <ActionButton title="Country" onClick={() => handleClick('Country')} icon={<img src={IconCountry} alt="Country Icon" style={{ width: '20px', height: '20px' }} />} sx={styles.icons.default} /> */}
          <ActionButton title="Currency Exchange" onClick={() => handleClick('Currency Exchange')} icon={<img src={IconCurrency} alt="Add Icon" style={{ width: '18px', height: '18px' }} />} sx={styles.icons.default} />
          {currentLocation?.pathname === '/analyticals' && (
            <>
              {widgetId && (
                <ActionButton
                  title="Delete"
                  onClick={() => handleClick('Delete')}
                  icon={<img src={IconDelete} alt="Delete Icon" style={{ width: '18px', height: '18px' }} />}
                  sx={styles.icons.delete}
                />
              )}
            </>
          )}

          {(currentLocation?.pathname === '/create-report') && (
                <ActionButton title="Save your report" onClick={() =>  handleClick('Save')} icon={<img src={IconSave} alt="Save Icon" style={{ width: '18px', height: '18px'}} />} sx={styles.icons.save} />
          )}
           {currentLocation?.pathname === `/edit-report/${widgetIds}` && (
                <ActionButton title="Save your report" onClick={() => handleClick('UpdateWidget')} icon={<img src={IconSave} alt="Save Icon" style={{ width: '18px', height: '18px'}} />} sx={styles.icons.save} />
          )}
          {(currentLocation?.pathname === '/create-report' || currentLocation?.pathname === `/edit-report/${widgetIds}`) && (
                <ActionButton title="Close" onClick={() =>  handleClick('Close')} icon={<CloseIcon fontSize="large" color='white' alt="Close Icon" style={{ width: '18px', height: '18px', background:'transparent', color:'#ffffff'}} />} sx={styles.icons.save} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default Index;
