import { 
    IconButton, 
    ListItemText, 
    Menu, MenuItem, 
    Paper, Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead,
    TableRow, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material'
import { Link, useNavigate } from "react-router-dom";
import React from 'react'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from '@mui/icons-material/Close';
import IconDelete from '../../assets/images/icon-delete-o.svg';
import IconEdit from '../../assets/images/icon-edit-o.svg';
import ListItemIcon from "@mui/material/ListItemIcon";
import { MoveDown, Share, FileDownload, Restore } from "@mui/icons-material";
import moment from 'moment';

const SearchResult = ( {openPopup, handleClose, result, onResponseGet} ) => {
    const navigate = useNavigate();


    const handelFileClick = (fileData) => {
        localStorage.setItem('vaultItemId', fileData?.folder__id)
        window.dispatchEvent(new CustomEvent('clickedFileData', { detail: { clickedData: fileData } }));
        const folderSlug = fileData?.folder_path?.split('/')[0] // Take the part before the slash
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, '-'); // Replace spaces with hyphens
        navigate(`/the-vault/${folderSlug}`);
        handleClose()
        
      };
    return (
        <>
        <Dialog open={openPopup} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                Search Results
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <TableContainer component={Paper} elevation={0}>                    
                    <Table className="w-full border-collapse table mb-0" sx={{ minWidth: 600 }} aria-label="simple table">
                        <TableHead>
                        <TableRow sx={{ background: '#fff' }}>
                            <TableCell sx={{ paddingLeft: '10px !important' }}>Name</TableCell>
                            <TableCell sx={{ paddingLeft: '10px !important' }}>Uploaded By</TableCell>
                            <TableCell sx={{ paddingLeft: '10px !important' }}>Action Taken Email</TableCell>
                            {/* <TableCell sx={{ paddingLeft: '10px !important' }}>Folder Name</TableCell>
                            <TableCell sx={{ paddingLeft: '10px !important' }}>Action Taken For</TableCell> */}
                            <TableCell sx={{ paddingLeft: '10px !important' }}>Size</TableCell>
                            <TableCell sx={{ paddingLeft: '10px !important' }}>Created At</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {result && result.length > 0 ? (
                            result.map((item, index) => {
                                const bytes = item?.size / 8; // Adjust 'size_in_bits' to the actual key holding bit value
                                const megabytes = bytes / (1024 * 1024);                                
                                // const folderSlug = item?.folder_path?.toLowerCase().replace(/\s+/g, "-");

                                return (
                                    <TableRow sx={{ border: 0 }} key={index}>
                                        <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                            {/* <Link to={`/the-vault/${folderSlug}`}>{item?.name}</Link><br/> */}
                                            <p onClick={() => handelFileClick(item)} style={{ cursor: "pointer" }}>{item?.name}</p>
                                            <small>{item?.match_type ? `(${item?.match_type})` : ''}</small>
                                        </TableCell>
                                        <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                            {item?.uploaded_by__email}
                                        </TableCell>
                                        <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                            {item?.action_taken_for__email}
                                        </TableCell>
                                        {/* <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                            {item?.folder__name}
                                        </TableCell>
                                        <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                            {item?.folder__action_taken_for__email}
                                        </TableCell> */}
                                        <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                            {megabytes.toFixed(4)}MB
                                        </TableCell>
                                        <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                            {moment(item?.created_at).format('DD MMM, YYYY')}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                    No results found
                                </TableCell>
                            </TableRow>
                        )}

                        </TableBody>
                    </Table>                    
                </TableContainer>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default SearchResult